import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { SetStateAction } from "react";

export interface ConfirmDialogPayload {
    response: boolean
    data: any
}

interface ConfirmDialogProps {
    title: string
    message: string
    visible: boolean
    payload: any
    setVisible : React.Dispatch<SetStateAction<boolean>>
    confirm : React.Dispatch<SetStateAction<ConfirmDialogPayload>>
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, message, visible, setVisible, payload, confirm }) => {
    function confirmClicked(positive: boolean) {
        setVisible(false);
        confirm({ response: positive, data: payload } as ConfirmDialogPayload);
    }

    return <IonModal isOpen={visible} onDidDismiss={() => setVisible(false)}>
    <IonHeader>
        <IonToolbar>
            <IonTitle>{title}</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent class="ion-padding">
        <p>{message}</p>
    </IonContent>
    <IonFooter>
        <IonToolbar>
            <IonButtons slot="end">
                <IonButton fill="solid" onClick={() => confirmClicked(true)}>Yes</IonButton>
                <IonButton fill="solid" onClick={() => confirmClicked(false)}>No</IonButton>
            </IonButtons>
        </IonToolbar>
    </IonFooter>
</IonModal>
}

export default ConfirmDialog;
import { IonApp, IonLoading, IonRouterOutlet, IonSplitPane, IonToast, setupIonicReact } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Menu from './components/Menu';
import Page from './pages/Page';
import Login from './pages/Login';
import { DefaultPage } from './Contract';
import { SetStateAction, useState } from 'react';

setupIonicReact();

export interface LoadingProps {
  loadingMessage : React.Dispatch<SetStateAction<string>>
  loadingShow : React.Dispatch<SetStateAction<boolean>>
  toastMessage : React.Dispatch<SetStateAction<string>>
  toastShow : React.Dispatch<SetStateAction<boolean>>
}

const App: React.FC = () => {
  const [isLoadingVisible, setLoadingVisible] = useState<boolean>(false);
  const [getLoadingMessage, setLoadingMessage] = useState<string>("");
  const [isToastVisible, setToastVisible] = useState<boolean>(false);
  const [getToastMessage, setToastMessage] = useState<string>("");

  function isLoggedIn() {
    const email = localStorage.getItem("email");
    const secret = localStorage.getItem("secret");
    const session = localStorage.getItem("session");
    const role = localStorage.getItem("roles");

    return (email && secret && session && role);
  }

  function onToastEnd() {
    setToastVisible(false);
    setToastMessage("");
  }

  if (isLoggedIn()) {
    return (
      <GoogleOAuthProvider clientId="116660682950-mnfugbovcu3nhnd2nue911hl8srbb3d1.apps.googleusercontent.com"><IonApp>
        <IonLoading isOpen={isLoadingVisible} message={getLoadingMessage}/>
        <IonReactRouter>
          <IonSplitPane contentId='main'>
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/" exact={true}>
                <Redirect to={DefaultPage} />
              </Route>
              <Route path="/page/:name" exact={true}>
                <Page loadingMessage={setLoadingMessage} loadingShow={setLoadingVisible} toastMessage={setToastMessage} toastShow={setToastVisible}/>
              </Route>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
        <IonToast isOpen={isToastVisible} message={getToastMessage} onDidDismiss={() => onToastEnd() } duration={5000}/>
      </IonApp></GoogleOAuthProvider>
    );
  } else {
    return (
      <GoogleOAuthProvider clientId="116660682950-mnfugbovcu3nhnd2nue911hl8srbb3d1.apps.googleusercontent.com"><IonApp>
        <IonLoading isOpen={isLoadingVisible} message={getLoadingMessage}/>
        <IonReactRouter>
          <Login loadingMessage={setLoadingMessage} loadingShow={setLoadingVisible} toastMessage={setToastMessage} toastShow={setToastVisible} />
        </IonReactRouter>
        <IonToast isOpen={isToastVisible} message={getToastMessage} onDidDismiss={() => setToastVisible(false) } duration={5000}/>
      </IonApp></GoogleOAuthProvider>
    );
  }
};

export default App;

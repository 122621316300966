const Homepage: React.FC = () => {
    return <>
        <div className="container">
            <h1>Selamat Datang</h1>
            <h3>di</h3>
            <h1>Rona Montessori</h1>
        </div>
    </>
}

export default Homepage;
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react"
import { SetStateAction, useEffect, useState } from "react"
import { ServerHost, createRequestPost } from "../Contract"

interface ParentDetailUpdateDialogProps {
    parentDetail: any
    showDialog : boolean
    setShowDialog : React.Dispatch<SetStateAction<boolean>>
}

const ParentDetailUpdateDialog: React.FC<ParentDetailUpdateDialogProps> = ({ parentDetail, showDialog, setShowDialog }) => {
    const [gender, setGender] = useState<number>(parentDetail.gender);
    const [birthdate, setBirthdate] = useState<string>(parentDetail.birthdate);
    const [religion, setReligion] = useState<string>(parentDetail.religion);
    const [address, setAddress] = useState<string>(parentDetail.address);
    const [phone, setPhone] = useState<string>(parentDetail.phone);
    const [education, setEducation] = useState<string>(parentDetail.education);
    const [profession, setProfession] = useState<string>(parentDetail.profession);
    const [company, setCompany] = useState<string>(parentDetail.company);
    const [companyAddress, setCompanyAddress] = useState<string>(parentDetail.companyAddress);
    const [companyPhone, setCompanyPhone] = useState<string>(parentDetail.companyPhone);
    const [salary, setSalary] = useState<number>(parentDetail.salary);

    useEffect(() => {
        if (showDialog) {
            reset();
        }
    }, [showDialog]);

    function reset() {
        setGender(parentDetail.gender);
        setBirthdate(parentDetail.birthdate);
        setReligion(parentDetail.religion);
        setAddress(parentDetail.address);
        setPhone(parentDetail.phone);
        setEducation(parentDetail.education);
        setProfession(parentDetail.profession);
        setCompany(parentDetail.company);
        setCompanyAddress(parentDetail.companyAddress);
        setCompanyPhone(parentDetail.companyPhone);
        setSalary(parentDetail.salary);
    }

    function save() {
        const request = createRequestPost("rona.app.parent.detail.update", {
            "parent": parentDetail.parent, 
            "gender": gender,
            "birthdate": birthdate,
            "religion": religion,
            "address": address,
            "phone": phone,
            "education": education,
            "profession": profession,
            "company": company,
            "companyAddress": companyAddress,
            "companyPhone": companyPhone,
            "salary": salary
        });

        fetch(ServerHost, request).then(async response => {
            if (response.ok) {
                var payload = await response.json();
                var body = payload.body;
    
                if (payload.type === "status" && body && body.code === 200) {
                    console.log("Success update parent detail: " + body);
                    setShowDialog(false);
                } else {
                    console.log("Error in update parent detail, received status: " + body.code);
                }
            } else {
                console.log("failed to update parent detail, received response: " + response.status);
            }
        }).catch((reason: any) => {
            console.log("Error to update parent detail", reason);
        });
    }

    function genderToString(gender: number) : string {
        if (gender) {
            return gender.toString();
        } else {
            return "";
        }
    }

    return <IonModal isOpen={showDialog} onDidDismiss={() => setShowDialog(false)}>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Update Parent Detail</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonItem>
                    <IonLabel>Gender:</IonLabel>
                    <IonSelect placeholder="Select Gender" value={genderToString(gender)} onIonChange={ e => setGender(e.detail.value!) }>
                        <IonSelectOption value="2">Female</IonSelectOption>
                        <IonSelectOption value="1">Male</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel>Birthdate:</IonLabel>
                    <IonInput type="date" value={birthdate} onIonChange={ e => setBirthdate(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Religion:</IonLabel>
                    <IonInput type="text" value={religion} onIonChange={ e => setReligion(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Education:</IonLabel>
                    <IonInput type="text" value={education} onIonChange={ e => setEducation(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Profession:</IonLabel>
                    <IonInput type="text" value={profession} onIonChange={ e => setProfession(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Address:</IonLabel>
                    <IonInput type="text" value={address} onIonChange={ e => setAddress(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Phone:</IonLabel>
                    <IonInput type="text" value={phone} onIonChange={ e => setPhone(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Company:</IonLabel>
                    <IonInput type="text" value={company} onIonChange={ e => setCompany(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Company Address:</IonLabel>
                    <IonInput type="text" value={companyAddress} onIonChange={ e => setCompanyAddress(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Company Phone:</IonLabel>
                    <IonInput type="text" value={companyPhone} onIonChange={ e => setCompanyPhone(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Salary:</IonLabel>
                    <IonInput type="text" value={salary} onIonChange={ e => setSalary(+e.detail.value!) }/>
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={() => save() }>Save</IonButton>
                    <IonButton fill="solid" onClick={() => reset() }>Reset</IonButton>
                    <IonButton fill="solid" onClick={() => setShowDialog(false) }>Cancel</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonModal>
}

export default ParentDetailUpdateDialog;

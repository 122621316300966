import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonFab, IonFabButton, IonFabList, IonGrid, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { LocalStorageParentDetail, LocalStorageStudentDetail, getGenderName, numberToString } from "../../Contract";
import { useEffect, useState } from "react";
import { getParentDetail, getStudentsByParent } from "../../components/peoples/GetPersons";
import { useHistory } from "react-router";
import AddPersonsDialog from "../../components/peoples/AddPersonsDialog";
import { personAddOutline, personOutline } from "ionicons/icons";
import ParentDetailUpdateDialog from "../../components/ParentDetailUpdateDialog";
import { LoadingProps } from "../../App";

interface ParentDetailPageProps extends LoadingProps {
    isManager: boolean
}

const ParentDetailPage: React.FC<ParentDetailPageProps> = (props) => {
    const parent = JSON.parse(localStorage.getItem(LocalStorageParentDetail)!);
    const [studentList, setStudentList] = useState<any>(JSON.parse("{}"));
    const [parentDetail, setParentDetail] = useState<any>(JSON.parse("{}"));
    const [addStudentDialog, showAddStudentDialog] = useState<boolean>(false);
    const [showParentUpdateDialog, setShowParentUpdateDialog] = useState<boolean>(false);
    const [addPersonType, setAddPersonType] = useState<number>(0);
    const history = useHistory();

    if (!parent) {
        window.location.replace("/");
    }

    useEffect(() => {
        props.loadingShow(false);
    }, [parentDetail, studentList]);

    useEffect(() => {
        props.loadingShow(true);
        setStudentList(JSON.parse("{}"));
        setParentDetail(JSON.parse("{}"));

        // getStudentsByParent(parent.email, setStudentList);
        // getParentDetail(parent.email, setParentDetail);
    }, []);

    useEffect(() => {
        if (!showParentUpdateDialog) {
            getParentDetail(parent.email, setParentDetail);
        }
    }, [showParentUpdateDialog]);

    useEffect(() => {
        if (!addStudentDialog) {
            getStudentsByParent(parent.email, setStudentList);
        }
    }, [addStudentDialog])

    function onStudentSelected(content : any) {
        localStorage.setItem(LocalStorageStudentDetail, JSON.stringify(content));
        history.push("/page/Student");
    }

    function onAddPersonClick(type: number) {
        setAddPersonType(type);
        showAddStudentDialog(true);
    }

    return <>
        <AddPersonsDialog loadingProps={props} type={addPersonType} isDialogShown={addStudentDialog} showDialog={showAddStudentDialog} passEmail={parent.email}/>
        <ParentDetailUpdateDialog parentDetail={parentDetail} showDialog={showParentUpdateDialog} setShowDialog={setShowParentUpdateDialog}/>
        <IonFab vertical="bottom" horizontal="end" edge={false} slot="fixed">
            <IonFabButton id="fab-addstudent">
                <IonIcon icon={personOutline}/>
            </IonFabButton>
            <IonFabList side="top">
                <IonFabButton id="fab-addstudent" hidden={!props.isManager} onClick={() => {onAddPersonClick(3)}}>
                    <IonIcon icon={personAddOutline}/>
                </IonFabButton>
                <IonFabButton id="fab-addparent" hidden={!props.isManager} onClick={() => {onAddPersonClick(2)}}>
                    <IonIcon icon={personAddOutline} />
                </IonFabButton>
                <IonFabButton id="fab-editdetail" onClick={() => {setShowParentUpdateDialog(true)}}>
                    <IonIcon icon={personOutline} />
                </IonFabButton>
            </IonFabList>
        </IonFab>
        <IonCard>
        <IonCardHeader>
            <IonCardTitle>{parent.name}</IonCardTitle>
            <IonCardSubtitle>{parent.email}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
            <IonGrid>
                <IonRow>
                    <IonCol>Gender:</IonCol>
                    <IonCol>{getGenderName(parentDetail.gender)}</IonCol>
                    <IonCol>Education:</IonCol>
                    <IonCol>{parentDetail.education}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>Religion:</IonCol>
                    <IonCol>{parentDetail.religion}</IonCol>
                    <IonCol>Profession:</IonCol>
                    <IonCol>{parentDetail.profession}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>Birthdate:</IonCol>
                    <IonCol>{parentDetail.birthdate}</IonCol>
                    <IonCol>Gaji:</IonCol>
                    <IonCol>{numberToString(parentDetail.salary)}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol></IonCol>
                    <IonCol>Company:</IonCol>
                    <IonCol>{parentDetail.company}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>Address:</IonCol>
                    <IonCol>{parentDetail.address}</IonCol>
                    <IonCol>Address:</IonCol>
                    <IonCol>{parentDetail.companyAddress}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>Phone:</IonCol>
                    <IonCol>{parentDetail.phone}</IonCol>
                    <IonCol>Phone:</IonCol>
                    <IonCol>{parentDetail.companyPhone}</IonCol>
                </IonRow>
            </IonGrid>
        </IonCardContent>
        <IonCardContent>
            <IonCardTitle>Students</IonCardTitle>
            <IonGrid>
                <IonRow key={"student.header"}>
                    <IonCol class="person-grid-header">Name</IonCol>
                    <IonCol class="person-grid-header">Birthdate</IonCol>
                    <IonCol class="person-grid-header">Gender</IonCol>
                    <IonCol class="person-grid-header">Parent</IonCol>
                </IonRow>
                {
                    Object.values<any>(studentList).map((student, index) => {
                        return <IonRow key={"student." + index}>
                            <IonCol class="person-grid-item" onClick={() => onStudentSelected(student)}>{student.name}</IonCol>
                            <IonCol class="person-grid-item">{student.birthdate}</IonCol>
                            <IonCol class="person-grid-item">{getGenderName(student.gender)}</IonCol>
                            <IonCol class="person-grid-item">{student.parentName}</IonCol>
                        </IonRow>
                    })
                }
                <IonCol>
                </IonCol>
            </IonGrid>
        </IonCardContent>
    </IonCard>
    </>
}

export default ParentDetailPage;
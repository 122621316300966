import { generateHmac } from "./components/SessionUtils";

export const ServerHost : string = "https://app.rona.id/api";
export const DefaultPage : string = "/page/Home";

export const RoleCodeTeacher : number = 20;
export const RoleNameTeacher : string = "Teacher";
export const RoleCodeParent : number = 60;
export const RoleNameParent : string = "Parent";

export const CurriculumTypeIdArea : number = 100;
export const CurriculumTypeIdSubject : number = 200;
export const CurriculumTypeIdMaterial : number = 300;

export const CurriculumTypeNameArea : string = "area";
export const CurriculumTypeNameSubject : string = "subject";
export const CurriculumTypeNameMaterial : string = "material";

export const LocalStorageStudentDetail : string = "student.detail";
export const LocalStorageParentDetail : string = "parent.detail";

export const StateInit : number = 0;
export const StateInProgress : number = 1;
export const StateSuccess : number = 2;
export const StateFail : number = 3;

export const JSONEmpty = JSON.parse("{}");

export function getRoleName(id : number) : string {
    switch(id) {
        case RoleCodeTeacher:
            return RoleNameTeacher;
        case RoleCodeParent:
            return RoleNameParent;
        default:
            return "N/A";
    }
}

export function getGenderName(id : number) : string {
    if (id === 1) {
        return "Male";
    } else if (id === 2) {
        return "Female";
    } else {
        return "Not Set";
    }
}

export function numberToString(num : number) : string {
    if (num === null || num === undefined) {
        return "";
    } else {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
}

export function isStringEmpty(str : string) : boolean {
    return str === null || str === undefined || str === "";
}

export function createRequestPost(type: string, params: Object) : Object {
    return createRequest(type, "POST", params);
}

function createRequest(type: string, method: string, params: Object) : Object {
    const email = getCredential("email");
    const role = getCredential("selectedRole");
    const session = getCredential("session");
    const secret = getCredential("secret");

    const timestamp = Date.now().toString();
    const hmac = generateHmac(secret, timestamp, session);

    var body = {
        "email": email,
        "role": +role,
        "timestamp": timestamp,
        "secret": hmac
    }

    Object.assign(body, params);

    return {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            "id": Math.random().toString().substring(2, 4),
            "type": type,
            "body": body
        })
    }
}

function getCredential(key : string) : string {
    var value = localStorage.getItem(key);
    if (!value) {
        value = "";
    }
    return value;
}

export function isObjectEmpty(objectName : any) : boolean {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
};
  
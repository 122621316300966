import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import { SetStateAction, useEffect, useState } from "react";
import { addParent, addStudent, addTeacher } from "./AddPersons";
import { isStringEmpty, StateFail, StateInit, StateInProgress, StateSuccess } from "../../Contract";
import { LoadingProps } from "../../App";

interface AddPersonProp {
    type: number,
    passEmail: string,
    isDialogShown : boolean,
    showDialog : React.Dispatch<SetStateAction<boolean>>,
    loadingProps : LoadingProps
}
const AddPersonsDialog: React.FC<AddPersonProp> = ({ loadingProps, type, isDialogShown, showDialog, passEmail }) => {
    const [email, setEmail] = useState<string>();
    const [family, setFamily] = useState<string>();
    const [name, setName] = useState<string>();
    const [birthdate, setBirthdate] = useState<string>();
    const [gender, setGender] = useState<number>();
    const [stUpdate, setStUpdate] = useState<number>(StateInit);

    var title = "Add Student";
    if (type === 1) {
        title = "Add Teacher";
    } else if (type === 2) {
        title = "Add Parent";
    }

    function onSaveClick() {
        if (email && name) {
            var emailLength = email.length;
            var nameLength = name.length;

            if (emailLength > 5 && emailLength < 256 && nameLength > 3 && nameLength < 100) {
                setStUpdate(StateInit);
                if (type === 1) {
                    addTeacher(setStUpdate, email, name);
                } else if (type === 2) {
                    addParent(setStUpdate, email, name, family);
                } else if (type === 3 && birthdate && gender) {
                    console.log("add student");
                    addStudent(setStUpdate, email, name, birthdate, gender);
                }
            }
        }
    }

    function getEmailLabel() : string {
        if (type === 3) {
            return "Parent E-mail:";
        } else {
            return "E-mail:";
        }
    }

    useEffect(() => {
        if (isDialogShown) {
            if (!isStringEmpty(passEmail) && type === 3) {
                setEmail(passEmail);
            } else {
                setEmail("");
            }

            if (!isStringEmpty(passEmail) && type === 2) {
                setFamily(passEmail);
            } else {
                setFamily("");
            }
            
            setName("");
            setBirthdate("");
            setGender(0);
        }
    }, [isDialogShown]);

    useEffect(() => {
        if (stUpdate === StateSuccess) {
            loadingProps.loadingShow(false);
            showDialog(false);
        } else if (stUpdate === StateInProgress) {
            loadingProps.loadingShow(true);
        } else if (stUpdate === StateFail) {
            loadingProps.loadingShow(false);
            loadingProps.toastMessage("Fail to add people");
            loadingProps.toastShow(true);
        }
    }, [stUpdate]);

    return <IonModal isOpen={isDialogShown} onDidDismiss={() => showDialog(false) }>
        <IonHeader>
            <IonToolbar>
                <IonTitle>{title}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonItem>
                    <IonLabel>{ getEmailLabel() }</IonLabel>
                    <IonInput type="email" placeholder="E-mail" value={email} onIonChange={ e => setEmail(e.detail.value!) }/>
                </IonItem>
                <IonItem hidden={type !== 2}>
                    <IonLabel>Relative E-mail</IonLabel>
                    <IonInput type="email" placeholder="Relative E-mail" value={family} onIonChange={ e => setFamily(e.detail.value!) }/>
                </IonItem>
                <IonItem>
                    <IonLabel>Name:</IonLabel>
                    <IonInput type="text" placeholder="Name" onIonChange={ e => setName(e.detail.value!) }/>
                </IonItem>
                <IonItem hidden={type !== 3}>
                    <IonLabel>Birthdate:</IonLabel>
                    <IonInput type="date" onIonChange={ e => setBirthdate(e.detail.value!) }/>
                </IonItem>
                <IonItem hidden={type !== 3}>
                    <IonLabel>Gender:</IonLabel>
                    <IonSelect placeholder="Select Gender" value={gender} onIonChange={ e => setGender(e.detail.value)}>
                        <IonSelectOption value="2">Female</IonSelectOption>
                        <IonSelectOption value="1">Male</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={() => showDialog(false)}>Cancel</IonButton>
                    <IonButton fill="solid" onClick={() => onSaveClick()}>Save</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonModal>
}

export default AddPersonsDialog;
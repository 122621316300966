import { IonAccordion, IonAccordionGroup, IonFab, IonFabButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { addOutline, createOutline, trashOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { LoadingProps } from "../App";
import ConfirmDialog, { ConfirmDialogPayload } from "../components/ConfirmDialog";
import CurriculumDialog from "../components/CurriculumDialog";
import { retrieveCurriculums, deleteCurriculum as confirmDeleteCurriculum } from "../components/Curriculums";
import './Curriculums.css';

const Curriculums: React.FC<LoadingProps> = (loadingProps) => {
    const [curriculumDialog,showCurriculumDialog] = useState<boolean>(false);
    const [curriculums,setCurriculums] = useState<any>(JSON.parse("{}"));
    const [updateCurriculum, setUpdateCurriculums] = useState<any>();
    const [confirmTitle, setConfirmTitle] = useState<string>("");
    const [confirmMessage, setConfirmMessage] = useState<string>("");
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
    const [confirmPayload, setConfirmPayload] = useState<any>({});
    const [confirmResponse, setConfirmResponse] = useState<ConfirmDialogPayload>({} as ConfirmDialogPayload);
    const [confirmResult, setConfirmResult] = useState<number>(0);
    var role = localStorage.getItem("selectedRole");
    var session = localStorage.getItem("session");

    useEffect(() => {
        if (role && session) {
            loadingProps.loadingMessage("Retrieving Data ...");
            loadingProps.loadingShow(true);
            retrieveCurriculums(setCurriculums, loadingProps.loadingShow);
        }
    }, [curriculumDialog]);

    useEffect(() => {
        if (confirmResult > 0 && role && session) {
            loadingProps.loadingMessage("Retrieving Data ...");
            loadingProps.loadingShow(true);
            retrieveCurriculums(setCurriculums, loadingProps.loadingShow);
        }
    }, [confirmResult]);

    useEffect(() => {
        if (confirmResponse && confirmResponse.data) {
            if (confirmResponse.response) {
                loadingProps.loadingMessage("Deleting Curricullum ...");
                loadingProps.loadingShow(true);
                setConfirmResult(0);
                confirmDeleteCurriculum(setConfirmResult, confirmResponse.data.id);
                console.log("process delete: " + confirmResponse.data.name);
            }
        }
    }, [confirmResponse]);

    function isObjectNotEmpty(obj: Object) : boolean {        
        return getNumOfObjectData(obj) > 0;
    }

    function getNumOfObjectData(obj: Object) : number {
        if (obj && obj instanceof Object) {
            return Object.keys(obj).length;
        } else {
            return 0;
        }
    }

    function addCurriculum() {
        setUpdateCurriculums({ id: "" });
        showCurriculumDialog(true);
    }

    function editCurriculum(curriculum: any) {
        setUpdateCurriculums(curriculum);
        showCurriculumDialog(true);
    }

    function deleteCurriculum(curriculum: any) {
        setConfirmTitle("Delete Curriculum");
        setConfirmMessage("Are you sure to delete curriculums: " + curriculum.name);
        setConfirmPayload(curriculum);
        setConfirmVisible(true);
    }

    return <>
        <CurriculumDialog curriculumDialog={curriculumDialog} showCurriculumDialog={showCurriculumDialog} curriculums={curriculums} editCurriculum={updateCurriculum}/>
        <ConfirmDialog title={confirmTitle} message={confirmMessage} visible={confirmVisible} setVisible={setConfirmVisible} payload={confirmPayload} confirm={setConfirmResponse} />
        <IonFab vertical="bottom" horizontal="end" edge={false} slot="fixed">
            <IonFabButton id="fab-curriculums" onClick={() => {addCurriculum()}}>
                <IonIcon icon={addOutline}/>
            </IonFabButton>
        </IonFab>
        <IonAccordionGroup multiple={false}>
            {
                Object.values<any>(curriculums).map((area, index) => {
                    return <IonAccordion value={area.id} key={index}>
                        <IonItem slot="header">
                            <IonLabel>{area.name  + " (" + getNumOfObjectData(area.items) + ")"}</IonLabel>
                            <IonIcon icon={createOutline} onClick={() => { editCurriculum(area) }} />
                            <IonIcon icon={trashOutline} onClick={() => { deleteCurriculum(area) }} hidden={ isObjectNotEmpty(area.items) }/>
                        </IonItem>
                        <IonAccordionGroup multiple={false} className="accordion-group-child" slot="content">
                        {
                            Object.values<any>(area.items).map((subject, _) => {
                                return <IonAccordion value={subject.id} key={area.id + "." + subject.id}>
                                    <IonItem slot="header">
                                    <IonLabel>{subject.name  + " (" + getNumOfObjectData(subject.items) + ")"}</IonLabel>
                                    <IonIcon icon={createOutline} onClick={() => { editCurriculum(subject) }}/>
                                    <IonIcon icon={trashOutline} onClick={() => { deleteCurriculum(subject) }} hidden={ isObjectNotEmpty(subject.items) }/>
                                </IonItem>
                                <IonAccordionGroup multiple={false} className="accordion-group-child" slot="content">
                                    {
                                        Object.values<any>(subject.items).map((material, _) => {
                                            return <IonAccordion value={material.id} key={area.id + "." + subject.id + "." + material.id}>
                                                <IonItem slot="header">
                                                    <IonLabel>{material.name}</IonLabel>
                                                    <IonIcon icon={createOutline} onClick={() => { editCurriculum(material) }}/>
                                                    <IonIcon icon={trashOutline} onClick={() => { deleteCurriculum(material) }} />
                                                </IonItem>
                                                <IonItem slot="content">
                                                    <IonLabel class="ion-text-wrap" color="secondary">{material.desc}</IonLabel>
                                                </IonItem>
                                            </IonAccordion>
                                        })
                                    }
                                </IonAccordionGroup>
                                </IonAccordion>
                            })
                        }
                        </IonAccordionGroup>
                    </IonAccordion>
                })
            }
        </IonAccordionGroup>
    </>
}

export default Curriculums;
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonText, IonTextarea, IonTitle, IonToolbar } from "@ionic/react"
import { SetStateAction, useEffect, useState } from "react"
import { updateLearning } from "./Learnings"
import { StateInit, StateSuccess } from "../../Contract"

interface LearningUpdateDialogProps {
    learningItem: any
    isShowDialog: boolean
    setShowDialog: React.Dispatch<SetStateAction<boolean>>
}

const LearningUpdateDialog: React.FC<LearningUpdateDialogProps> = ({ learningItem, isShowDialog, setShowDialog}) => {
    const [txProgress, setTxProgress] = useState<string>(learningItem.notes);
    const [stUpdate, setStUpdate] = useState<number>(StateInit);

    function onUpdateClicked() {
        setStUpdate(StateInit);

        const valProgress = ((txProgress && learningItem.notes !== txProgress) ? (appendProgressHistory()) : undefined);

        updateLearning(learningItem.id, setStUpdate, undefined, undefined, undefined, valProgress);
    }

    function appendProgressHistory() : string {
        if (learningItem.notes) {
            return txProgress + "\n\n" + learningItem.notes;
        } else {
            return txProgress;
        }
    }

    useEffect(() => {
        if (stUpdate === StateSuccess) {
            learningItem.notes = appendProgressHistory;
            setShowDialog(false);
        }
    }, [stUpdate]);

    useEffect(() => {
        setTxProgress("");
    }, [isShowDialog])
    
    return <IonModal isOpen={isShowDialog} onDidDismiss={() => { setShowDialog(false) }}>
         <IonHeader>
            <IonToolbar>
                <IonTitle>{learningItem.curriculumName}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonItem>
                    <IonLabel>Area:</IonLabel>
                    <IonInput readonly={true}>{learningItem.curriculumArea}</IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Subject:</IonLabel>
                    <IonInput readonly={true}>{learningItem.curriculumSubject}</IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Description: </IonLabel>
                    <IonTextarea readonly={true}>{learningItem.curriculumDesc}</IonTextarea>
                </IonItem>
                <IonItem>
                    <IonLabel>Student:</IonLabel>
                    <IonLabel>{learningItem.studentName}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Teacher:</IonLabel>
                    <IonLabel>{learningItem.teacherName}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Progress:</IonLabel>
                    <IonTextarea
                        readonly={false} autoGrow={true}
                        placeholder={'31/12/2024\nWhat, how, and extra notes'}
                        value={txProgress} onIonChange={ e => setTxProgress(e.detail.value!)}>
                    </IonTextarea>
                </IonItem>
                <IonItem>
                    <IonTextarea readonly={true} autoGrow={false}>{learningItem.notes}</IonTextarea>
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={() => onUpdateClicked()}>Update</IonButton>
                    <IonButton fill="solid" onClick={() => setShowDialog(false)}>Cancel</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonModal>
}

export default LearningUpdateDialog;
